let colors = {
  white: `#fff`,
  black: `#29292b`,
  grey: `#4b4b4d`,
  red: `#b63720`,
  blue: `#68b7d4`,
  lightGreen: `#949d61`,
  darkGreen: `#686645`,
  yellow: `#e9b745`,
  cultured: `#f8f7f6`,
  platinum: `#eae4de`,
  timberWolf: `#d0cec6`,
  naturkartanMapBg: `#f3f3ef`,
}

colors = {
  ...colors,
  // by function:
  imageBackground: colors.lightGreen,
}

export default colors
