import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { rem } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import { Heading1 } from "../styled/heading"
import { Heading2 } from "../styled/heading"
import { ReactComponent as SvgArrowRight } from "../../assets/images/icons/arrow-right.svg"

const Container = styled.section`
  // ${({ theme }) => theme.grid.container()}

  > div {
    height: 60vh;
    max-height: ${rem(480)};
    position: relative;
    overflow: hidden;
    // border-radius: ${rem(8)};
    background-color: ${({ theme }) => theme.colors.darkGreen};

    @media ${({ theme }) => theme.mq.mediumDown} {
      max-height: 60vh;
    }

    @media ${({ theme }) => theme.mq.xsmallDown} {
      max-height: 60vh;
    }

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.8)
      );
    }
  }
`

const Media = styled.div`
  > video,
  > .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    position: absolute !important;
    z-index: 1;
    top: 0;
    left: 0;
  }

  > video {
    height: 100%;
    width: 130vh;
    min-width: 100%;
    min-height: 56vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const Text = styled.div`
  ${({ theme }) => theme.grid.container()}
  z-index: 3;
  position: relative;
  color: ${({ theme }) => theme.colors.white};
  height: 100%;

  .-title {
    font-size: ${rem(24)};
    margin-bottom: 0.4em;
    color: ${({ theme }) => theme.colors.yellow};
    ${({ theme }) => theme.fonts.set(`primary`, `semibold`)};
  }

  .-description {
    color: ${({ theme }) => theme.colors.white};
    font-size: ${rem(18)};
    ${({ theme }) => theme.fonts.set(`primary`, `normal`)};
    max-width: 55ch;
    padding-bottom: ${rem(60)};
  }

  a {
    ${({ theme }) => theme.fonts.set(`primary`, `semibold`)};

    color: ${({ theme }) => theme.colors.white};

    &:hover {
      color: ${({ theme }) => theme.colors.white};

      svg {
        transform: translateX(0.3em);
      }
    }

    &:active {
      opacity: 0.6;
    }

    span {
      margin-right: 0.25em;
    }

    svg {
      width: 1.4em;
      height: 1.4em;
      margin-top: -0.1em;
      transition: transform 0.2s ${({ theme }) => theme.easings.default};
    }
  }
`

const TextContainer = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100%;
`

const MediaBlock = ({
  data,
  headingLevel = 2,
  descriptionHeadingLevel = 3,
  ...props
}) => {
  if (!data) return null

  return (
    <Container {...props}>
      <div>
        <Media>
          {data.video && (
            <video
              src={data.video}
              preload="auto"
              playsInline
              autoPlay
              loop
              muted
            />
          )}

          {data.image && (
            <GatsbyImage
              image={data.image.childImageSharp.gatsbyImageData}
              alt={data.title}
            />
          )}
        </Media>

        <Text>
          <TextContainer>
            <div>
              {data.title && (
                <Heading1
                  as={`h${headingLevel}`}
                  dangerouslySetInnerHTML={{ __html: data.title }}
                  className="-title"
                />
              )}

              {data.description && (
                <Heading2
                  as={`h${descriptionHeadingLevel}`}
                  dangerouslySetInnerHTML={{ __html: data.description }}
                  className="-description"
                />
              )}

              {data.cta && data.cta.url && data.cta.title && (
                <Link to={data.cta.url}>
                  <span dangerouslySetInnerHTML={{ __html: data.cta.title }} />

                  <SvgArrowRight aria-hidden="true" />
                </Link>
              )}
            </div>
          </TextContainer>
        </Text>
      </div>
    </Container>
  )
}

export default MediaBlock
