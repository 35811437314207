import React from "react"
import styled, { css } from "styled-components"
import { rem } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import PostCardHighlight from "../post-card-highlight"
import HexagonIcon from "../hexagon-icon"
import { Label2 } from "../styled/label"
import { anchorStyles } from "../styled/anchor"
import { ReactComponent as SvgArrowRight } from "../../assets/images/icons/arrow-right.svg"

const Container = styled.section`
  padding: ${rem(10)} 0;
  background-color: ${({ theme }) => theme.colors.timberWolf};

  > div {
    ${({ theme }) => theme.grid.container()}
  }
`

const Heading = styled.h2`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.darkGreen};
  margin-bottom: ${rem(30)};
  margin-top: ${rem(30)};

  @media ${({ theme }) => theme.mq.smallDown} {
    margin-bottom: ${rem(20)};
  }

  > .-icon {
    width: 3em;
    height: 3em;
    margin-right: 0.75em;

    svg {
      path {
        fill: ${({ theme }) => theme.colors.white};
      }
    }
  }

  > i {
    ${({ theme }) => theme.fonts.set(`primary`, `semibold`)}
    text-transform: none;
  }
`

const Highlights = styled.ul`
  display: grid;
  grid-gap: ${rem(15)};
  grid-template-columns: 2fr 1fr;

  @media ${({ theme }) => theme.mq.mediumDown} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${({ theme }) => theme.mq.xsmallDown} {
    grid-template-columns: 1fr;
  }

  ${(props) => {
    return (
      props.reversed &&
      css`
        transform: scale(-1, 1);
        > li {
          transform: scale(-1, 1);
        }
      `
    )
  }}

  > li:first-child {
    grid-row: 1 / 3;

    @media ${({ theme }) => theme.mq.mediumDown} and ${({ theme }) =>
        theme.mq.xsmallUp} {
      grid-column: 1 / 3;
    }

    > * {
      @media ${({ theme }) => theme.mq.mediumUp} {
        height: 100%;
      }
    }
  }
`

const More = styled.div`
  margin-top: ${rem(20)};
  margin-bottom: ${rem(20)};

  a {
    color: ${({ theme }) => theme.colors.darkGreen};

    &:hover {
      svg {
        transform: translateX(0.3em);
      }
    }

    span {
      ${anchorStyles}

      margin-right: 0.25em;
    }

    svg {
      width: 1.4em;
      height: 1.4em;
      margin-top: -0.1em;
      transition: transform 0.2s ${({ theme }) => theme.easings.default};
    }
  }
`

const PostsHighlights = ({
  data,
  heading,
  headingLevel = 2,
  moreText,
  moreLink,
  reversed,
  showHeading = true,
  icon,
}) => {
  if (!data || !data.length) return null

  return (
    <Container>
      <div>
        {showHeading && (
          <Heading as={`h${headingLevel}`}>
            {icon && (
              <HexagonIcon
                iconSvg={icon}
                className="-icon"
                aria-hidden="true"
                iconColor="cultured"
              />
            )}

            <Label2 dangerouslySetInnerHTML={{ __html: heading }} />
          </Heading>
        )}

        <Highlights reversed={reversed}>
          {data.map((item, i) => (
            <li key={i}>
              <PostCardHighlight data={item} major={i === 0} />
            </li>
          ))}
        </Highlights>

        {moreText && moreLink && (
          <More>
            <Link to={moreLink}>
              <span dangerouslySetInnerHTML={{ __html: moreText }} />

              <SvgArrowRight aria-hidden="true" />
            </Link>
          </More>
        )}
      </div>
    </Container>
  )
}

export default PostsHighlights
