const typesCategories = {
  packages: [`117`],
  stays: [`55`, `56`, `123`, `122`, `89`, `81`],
  eatDrink: [`54`, `106`],
  biking: [`31`, `120`, `8`, `16`, `74`],
  hiking: [`33`, `109`, `116`, `7`, `35`],
  fishing: [`26`, `118`, `105`, `115`],
  riding: [`34`],
  canoeing: [`37`, `80`],
  more: [],
}

const typesCategoriesIncluded = {
  ...typesCategories,
  stays: [`55`, `56`, `123`, `122`, `89`, `81`],
  eatDrink: [`54`, `106`],
  biking: [`31`, `120`, `8`, `16`, `74`, `55`, `56`],
  hiking: [`33`, `109`, `116`, `7`, `55`, `56`, `35`],
  fishing: [`26`, `118`, `105`, `115`, `55`, `56`],
  riding: [`34`, `55`, `56`],
  canoeing: [`37`, `80`, `55`, `56`],
}

const typesCategoriesPreselected = {
  ...typesCategories,
  biking: [`31`, `16`], // biking, mountainbiking
  hiking: [`33`, `35`], // hiking, national-park
  fishing: [`26`], // fishing
  riding: [`34`], // riding
  canoeing: [`37`], // canoeing
  more: [`35`, `25`, `14`, `113`, `77`, `76`], // national-park, nature-reserve, to-do, tourist, hub, guide
}

const typesSitesSort = {
  stays: { popularity: `DESC` },
  eatDrink: { popularity: `DESC` },
}

module.exports = {
  typesCategories,
  typesCategoriesIncluded,
  typesCategoriesPreselected,
  typesSitesSort,
}
