import { ReactComponent as SvgPackage } from "../../assets/images/icons/package.svg"
import { ReactComponent as SvgHouse } from "../../assets/images/icons/house.svg"
import { ReactComponent as SvgKnifeFork } from "../../assets/images/icons/knife-fork.svg"
import { ReactComponent as SvgHiking } from "../../assets/images/icons/hiking.svg"
import { ReactComponent as SvgBiking } from "../../assets/images/icons/biking.svg"
import { ReactComponent as SvgRiding } from "../../assets/images/icons/riding.svg"
import { ReactComponent as SvgFishing } from "../../assets/images/icons/fishing.svg"
import { ReactComponent as SvgCanoeing } from "../../assets/images/icons/canoeing.svg"
import { ReactComponent as SvgMore } from "../../assets/images/icons/more.svg"

const icons = {
  packages: SvgPackage,
  stays: SvgHouse,
  eatDrink: SvgKnifeFork,
  hiking: SvgHiking,
  biking: SvgBiking,
  riding: SvgRiding,
  fishing: SvgFishing,
  canoeing: SvgCanoeing,
  more: SvgMore,
}

const TypeIconSvg = ({ name, ...props }) => {
  if (!name) return null

  return icons[name](props)
}

export default TypeIconSvg
