import { rem } from "polished"

const infobox = {
  color: ({ theme }) => theme.colors.grey,
  backgroundColor: ({ theme }) => theme.colors.white,
  borderColor: ({ theme }) => theme.colors.platinum,
  iconColor: ({ theme }) => theme.colors.darkGreen,
  borderRadius: rem(8),
}

export default infobox
