export default [
  {
    code: `en`,
    title: `English`,
    url: `https://en.tiveden.se`,
  },
  {
    code: `sv`,
    title: `Svenska`,
    url: `https://www.tiveden.se`,
  },
  {
    code: `de`,
    title: `Deutsch`,
    url: `https://de.tiveden.se`,
  },
  {
    code: `nl`,
    title: `Nederlands`,
    url: `https://nl.tiveden.se`,
  },
  // {
  //   code: `fi`,
  //   title: `Suomi`,
  //   url: `https://fi.tiveden.se`,
  // },
]
