import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import { anchorStyles } from "../styled/anchor"
import EventCard from "../event-card"
import HexagonIcon from "../hexagon-icon"
import { Label2 } from "../styled/label"
import { ReactComponent as SvgArrowRight } from "../../assets/images/icons/arrow-right.svg"

const Container = styled.section`
  ${({ theme }) => theme.grid.container()}
`

const Grid = styled.ul`
  ${({ theme }) => theme.grid.grid()}
  ${({ theme }) => theme.grid.gridGutterX(null, { nested: true })}
  ${({ theme }) => theme.grid.gridGutterY(16)}

  > li {
    ${({ theme }) => theme.grid.cell(4)}

    @media ${({ theme }) => theme.mq.mediumDown} {
      ${({ theme }) => theme.grid.cell(12)}
    }

    > * {
      width: 100%;
    }
  }
`

const Heading = styled.h2`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.darkGreen};
  margin-bottom: ${rem(30)};
  margin-top: ${rem(30)};

  @media ${({ theme }) => theme.mq.smallDown} {
    margin-bottom: ${rem(20)};
  }

  > .-icon {
    width: 3em;
    height: 3em;
    margin-right: 0.75em;

    svg {
      path {
        fill: ${({ theme }) => theme.colors.white};
      }
    }
  }

  > i {
    ${({ theme }) => theme.fonts.set(`primary`, `semibold`)}
    text-transform: none;
  }
`

const More = styled.div`
  margin-top: ${rem(20)};
  margin-bottom: ${rem(20)};

  a {
    color: ${({ theme }) => theme.colors.darkGreen};

    &:hover {
      svg {
        transform: translateX(0.3em);
      }
    }

    span {
      ${anchorStyles}

      margin-right: 0.25em;
    }

    svg {
      width: 1.4em;
      height: 1.4em;
      margin-top: -0.1em;
      transition: transform 0.2s ${({ theme }) => theme.easings.default};
    }
  }
`

const EventsUpcoming = ({
  data,
  moreText,
  moreLink,
  heading,
  headingLevel = 2,
  showHeading = true,
  icon,
}) => {
  return (
    <Container>
      {showHeading && (
        <Heading as={`h${headingLevel}`}>
          {icon && (
            <HexagonIcon
              iconSvg={icon}
              className="-icon"
              aria-hidden="true"
              iconColor="cultured"
            />
          )}

          <Label2 dangerouslySetInnerHTML={{ __html: heading }} />
        </Heading>
      )}

      <Grid>
        {data.map((item, i) => (
          <li key={i}>
            <EventCard data={item} />
          </li>
        ))}
      </Grid>

      {moreText && moreLink && (
        <More>
          <Link to={moreLink}>
            <span dangerouslySetInnerHTML={{ __html: moreText }} />

            <SvgArrowRight aria-hidden="true" />
          </Link>
        </More>
      )}
    </Container>
  )
}

export default EventsUpcoming
