import React from "react"
import PostsHighlights from "../posts-highlights"
import { chunk } from "lodash"

const PostsHighlightsFullScreen = ({
  data,
  heading,
  headingLevel = 2,
  moreText,
  moreLink,
  icon,
}) => {
  if (!data || !data.length) return null

  const postChunks = chunk(data, 3)

  return (
    <>
      {postChunks.map((chunk, i) => {
        return (
          <PostsHighlights
            key={`highlights-${i}`}
            data={chunk}
            icon={icon}
            heading={heading}
            headingLevel={headingLevel}
            showHeading={i === 0}
            moreText={moreText}
            moreLink={moreLink}
            reversed={(i + 1) % 2 === 0}
          />
        )
      })}
    </>
  )
}

export default PostsHighlightsFullScreen
