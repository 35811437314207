import React from "react"
import styled, { css } from "styled-components"

const Container = styled.div`
  width: 3em;
  height: 3em;
  position: relative;
  background: ${({ theme }) => theme.colors.lightGreen};
  border-radius: 50%;

  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    padding: 27.5%;
    z-index: 2;

    path {
      fill: ${({ theme, $bgColor }) =>
        theme.colors[$bgColor] || `currentColor`};
    }

    ${({ $outline }) =>
      $outline &&
      css`
        fill: none;
        stroke: currentColor;
        stroke-width: 5px;
        filter: none;

        path {
          transform: scale(0.99);
          transform-origin: center center;
        }
      `}
  }
`

const HexagonIcon = ({ iconSvg, outline, bgColor, ...props }) => {
  if (!iconSvg) return null

  return (
    <Container $outline={outline} $bgColor={bgColor} {...props}>
      {iconSvg}
    </Container>
  )
}

export default HexagonIcon
