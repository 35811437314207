import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import { ParagraphLarge } from "../styled/paragraph"
import { ReactComponent as SvgFacebook } from "../../assets/images/icons/facebook.svg"
import logoUrl from "../../assets/images/tiveden-logo-white.png"

const Container = styled.section``

const Logos = styled.h2`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media ${({ theme }) => theme.mq.xlargeDown} {
    justify-content: center;
  }

  a {
    margin-right: 1.25em;
    margin-bottom: 1.25em;
    transition: opacity 0.2s ${({ theme }) => theme.easings.default};

    &:hover {
      opacity: 0.8;
    }

    &:active {
      transition: none;
      opacity: 0.5;
    }
  }

  img {
    max-width: 10em;
    height: auto;
    display: block;
  }
`

const Description = styled(ParagraphLarge)`
  color: inherit;
`

const Social = styled.ul`
  margin-top: 3em;

  @media ${({ theme }) => theme.mq.xlargeDown} {
    margin-top: 1.5em;
  }

  li {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 1em;
    }
  }

  a {
    width: 1.875em;
    height: 1.875em;
    padding: 0.3em;
    display: block;
    transition: color 0.2s ${({ theme }) => theme.easings.default};

    &:hover {
      color: ${({ theme }) => theme.colors.darkGreen};
    }

    &:active {
      transition: none;
      color: ${({ theme }) => theme.colors.white};
    }
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`

const About = ({ ...props }) => {
  const { config } = useStaticQuery(graphql`
    query {
      config: configJson {
        footer {
          about
        }
      }
    }
  `)

  return (
    <Container {...props}>
      <Logos>
        <Link to="/" title="Tiveden">
          <img src={logoUrl} alt="Tiveden" />
        </Link>
      </Logos>

      <Description dangerouslySetInnerHTML={{ __html: config.footer.about }} />

      <Social>
        <li>
          <Link
            to="https://www.facebook.com/visittiveden"
            target="_blank"
            title="Facebook"
            aria-label="Facebook"
          >
            <SvgFacebook />
          </Link>
        </li>
      </Social>
    </Container>
  )
}

export default About
