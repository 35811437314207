import styled, { css } from "styled-components"

const hrStyles = css`
  width: 60%;
  height: 1px;
  margin-left: auto;
  margin-right: auto;
  background-color: ${({ theme }) => theme.colors.white};
`

const Hr = styled.hr`
  ${hrStyles}
`

export { Hr, hrStyles }
