import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled, { css } from "styled-components"
import { rem } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import { Heading4 } from "../styled/heading"

const Container = styled.div`
  display: flex;

  @media ${({ theme }) => theme.mq.xlargeDown} {
    justify-content: center;
  }

  @media ${({ theme }) => theme.mq.smallDown} {
    flex-direction: column;
  }

  > *:not(:last-child) {
    margin-right: 5em;

    @media ${({ theme }) => theme.mq.smallDown} {
      margin-bottom: 2.5em;
      margin-right: 0;
    }
  }
`

const Group = styled.section`
  ${Heading4} {
    margin-bottom: ${rem(15)};
    color: ${({ theme }) => theme.colors.yellow};
  }

  ul {
    font-size: 1.5em; /* 24 */

    ${({ $secondary }) =>
      $secondary &&
      css`
        column-count: 2;
        column-gap: 3em;
        font-size: 1.125em; /* 18 */

        @media ${({ theme }) => theme.mq.xlargeDown} {
          column-count: 1;
        }
      `}
  }

  li {
    width: 100%;
    margin-bottom: 0.5em;
    line-height: 1.2;
    display: inline-block;
    clear: both;

    ${({ $secondary }) =>
      $secondary &&
      css`
        margin-top: 0.2em;
        margin-bottom: 0.6em;
      `}
  }

  a {
    transition: opacity 0.2s ${({ theme }) => theme.easings.default};

    &:hover {
      opacity: 0.7;
    }

    &:active {
      transition: none;
      opacity: 0.4;
    }
  }
`

const Menus = ({ ...props }) => {
  const { config, pages } = useStaticQuery(graphql`
    query {
      pages: allPagesJson {
        nodes {
          path
          title
        }
      }

      config: configJson {
        types {
          slug
          title
        }

        footer {
          planYourTrip: plan_your_trip
          explore {
            title
            items {
              url
              title
            }
          }
        }
      }
    }
  `)

  return (
    <Container {...props}>
      <Group>
        <Heading4
          as="h2"
          dangerouslySetInnerHTML={{ __html: config.footer.planYourTrip }}
        />

        <ul>
          {config.types.map((type, i) => (
            <li key={i}>
              <Link to={`/${type.slug}`}>{type.title}</Link>
            </li>
          ))}
        </ul>
      </Group>

      <Group $secondary={true}>
        <Heading4
          as="h2"
          dangerouslySetInnerHTML={{
            __html: config.footer.explore.title,
          }}
        />

        <ul>
          {config.footer.explore.items.map((item, i) => (
            <li key={i}>
              <Link to={item.url}>{item.title}</Link>
            </li>
          ))}

          {pages.nodes.length > 0 &&
            pages.nodes.map((item, i) => (
              <li key={i}>
                <Link to={item.path}>{item.title}</Link>
              </li>
            ))}
        </ul>
      </Group>
    </Container>
  )
}

export default Menus
