import React, { useState } from "react"
import styled, { css } from "styled-components"
import { useWindowWidth } from "@react-hook/window-size/throttled"
import { Carousel } from "@outdoormap/gatsby-plugin-brand-sites"

import SiteCard from "../site-card"
import SiteCardStage from "../site-card-stage"
import { breakpoints } from "../../theme/mq"

const Container = styled.div`
  .carousel__back-button,
  .carousel__next-button {
    top: 6em;

    @media ${({ theme }) => theme.mq.largeDown} {
      top: 8em;
    }

    ${({ $type }) =>
      $type === `hiking` &&
      css`
        top: 10em;

        @media ${({ theme }) => theme.mq.largeDown} {
          top: 13em;
        }

        @media ${({ theme }) => theme.mq.xsmallDown} {
          top: 11em;
        }
      `}
  }
`

const SitesCarousel = ({
  data,
  type,
  visibleSlides,
  hideTypeIcons,
  ...props
}) => {
  const [theVisibileSlides, setTheVisibleSlides] = useState(visibleSlides)
  const winWidth = useWindowWidth({ initialWidth: breakpoints.xxlarge })

  if (!data || !data.length) return null

  const setVisibleSlides = (count) => {
    if (typeof window === `undefined`) return false
    window.setTimeout(() => setTheVisibleSlides(count), 50)
    return true
  }

  if (winWidth < breakpoints.xsmall) {
    if (visibleSlides >= 4 && theVisibileSlides != 1) setVisibleSlides(1)
    if (visibleSlides == 3 && theVisibileSlides != 1) setVisibleSlides(1)
    if (visibleSlides == 2 && theVisibileSlides != 1) setVisibleSlides(1)
  } else if (winWidth < breakpoints.medium) {
    if (visibleSlides >= 4 && theVisibileSlides != 2) setVisibleSlides(2)
    if (visibleSlides == 3 && theVisibileSlides != 1) setVisibleSlides(1)
  } else if (winWidth < breakpoints.xlarge) {
    if (visibleSlides >= 4 && theVisibileSlides != 3) setVisibleSlides(3)
    if (visibleSlides == 3 && theVisibileSlides != 2) setVisibleSlides(2)
  } else if (winWidth >= breakpoints.xlarge) {
    if (theVisibileSlides != visibleSlides) setVisibleSlides(visibleSlides)
  }

  const slides = data.map((site) =>
    type === `hiking` ? (
      <SiteCardStage key={site.id} data={site} />
    ) : (
      <SiteCard key={site.id} data={site} hideTypeIcon={hideTypeIcons} />
    )
  )

  return (
    <Container $type={type} {...props}>
      <Carousel slides={slides} visibleSlides={theVisibileSlides} {...props} />
    </Container>
  )
}

export default SitesCarousel
