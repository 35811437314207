import { rgba, rem } from "polished"

const infoblocks = {
  backgroundColor: ({ theme }) => rgba(theme.colors.white, 0.6),
  openBackgroundColor: ({ theme }) => theme.colors.white,
  borderColor: ({ theme }) => theme.colors.platinum,
  borderRadius: rem(8),
}

export default infoblocks
