import styled, { css } from "styled-components"

const anchorStyles = css`
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 3px;
  color: ${({ theme }) => theme.colors.darkGreen};

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.5;
  }
`

const Anchor = styled.a`
  ${anchorStyles}
`

export { Anchor, anchorStyles }
